<template>
  <div class="text">
    <TopBar :type="2" :pageTitle="content ? content.exam_info.exam_name : ''" />
    <div class="sheet" @click="sheetModal = true"></div>
    <div class="tip-bg">
      <div class="tip" v-if="content">
        <p class="countdown">
          考试倒计时：<span class="date">{{ limitTime }}</span>
        </p>
        <p class="score">
          总分{{ content.exam_info.score }}分，{{
            content.exam_info.pass
          }}分及格
        </p>
      </div>
    </div>
    <ul v-for="item in content.questions" :key="item.type" class="topic">
      <li v-if="item.question_list.length > 0">
        <div class="title">
          {{ item.type_name }}
          <span class="sub-title"
            >（共{{ item.number }}题，合计{{ item.total_score }}分）</span
          >
        </div>
        <ul class="category"  v-if="item.type != 4">
          <li v-for="(timu, timuIndex) in item.question_list" :key="timu.id">
            <div class="question-title">
              {{ timuIndex + 1 }}、{{ timu.question }}
            </div>
            <label
              class="question-data"
              v-for="data in timu.selectdata"
              :key="data.key"
              :class="{
                active: selectedDta[
                  `question_${item.type}_${timu.id}`
                ].includes(data.key),
              }"
            >
              <input
                v-if="item.type != 2"
                type="radio"
                :name="'timu_' + timu.id"
                :value="data.key"
                v-model="selectedDta[`question_${item.type}_${timu.id}`]"
              />
              <input
                v-else
                type="checkbox"
                :name="'timu_' + timu.id"
                :value="data.key"
                v-model="selectedDta[`question_${item.type}_${timu.id}`]"
              />
              <span>{{ data.key }} {{ data.value }}</span>
            </label>
          </li>
        </ul>
        <ul class="category" v-else>
          <li v-for="(timu, timuIndex) in item.question_list" :key="timu.id">
            <div class="question-title">
              {{ timuIndex + 1 }}、{{ timu.question }}
            </div>
            <label
              class="question-data"
              v-for="(data,index) in timu.answer_count"
              :key="data.key"
              :class="{
                active: selectedDta[
                  `question_${item.type}_${timu.id}`
                ].includes(data.key),
              }"
            >
              <span>填空{{ index + 1 }}</span
                  ><input
                    class="border"
                    type="text"
                    value=""
                    :name="'timu_'+timu.id "
                    v-model="selectedDta[`question_${item.type}_${timu.id}`][index]"
                  />
            </label>
          </li>
        </ul>
      </li>
    </ul>
    <button class="sub-btn" @click="subBtn">提交</button>
    <div class="sheet-modal" v-if="sheetModal">
      <div class="content">
        <p class="content-title">
          答题卡<span class="close" @click="sheetModal = false"></span>
        </p>
        <ul
          class="answer-sheet"
          v-for="item in content.questions"
          :key="item.type_name"
        >
          <li class="category" v-if="item.question_list.length > 0">
            <p class="category-title">{{ item.type_name }}</p>
            <span
              class="test-num-box"
              :class="
                selectedDta[`question_${item.type}_${num.id}`] != ''
                  ? 'active'
                  : ''
              "
              v-for="(num, index) in item.question_list"
              :key="num.id"
              >{{ index + 1 }}</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import TopBar from "@/m/common/topBar.vue";
import { mapMutations } from "vuex";
export default {
  name: "TextContent",
  data() {
    return {
      sheetModal: false,
      content: "",
      selected: "",
      selectedDta: "",
      limitTime: "",
      downTime:'',//考场时间是否结束
      downTimer:'',//考试时间是否结束
    };
  },
  components: {
    TopBar,
  },
  mounted() {
    this.getTextContent(); //获取考试内容
  },
  methods: {
    ...mapMutations(["ShowTipModal", "CloseTipModal"]), //登录弹窗
    //获取考试内容
    getTextContent() {
      let url =
        this.$route.params.type == "1"
          ? "/v1/personal/goExam"
          : "/v1/openClass/goExam";
      this.$axios
        .post(
          url,
          {
            exam_id: this.$route.params.id,
          },
          { useLog: true }
        )
        .then((res) => {
          if (res.code == 0) {
            let content = res.data;
            let endTime = res.data.exam_info.end_time*1000 
           if(this.$route.params.type == "2"){
            this.downTimeOver(endTime)
           }
            // if(!content.questions[1]){
            //   content.questions[1] = {};
            //   content.questions[1].question_list = [];
            // }
            // if(!content.questions[2]){
            //   content.questions[2] = {};
            //   content.questions[2].question_list = [];
            // }
            // if(!content.questions[3]){
            //   content.questions[3] = {};
            //   content.questions[3].question_list = [];
            // }
            let questions = [];

            for (let i in content.questions) {
              if (!content.questions[i]) {
                content.questions[i] = {};
                content.questions[i].question_list = [];
              }

              questions.push(...content.questions[i].question_list);
            }
            this.content = content;
            // let questions = [...content.questions[1].question_list, ...content.questions[2].question_list, ...content.questions[3].question_list];
            let arr = {};

            for (let i = 0; i < questions.length; i++) {
              let key = "question_" + questions[i].type + "_" + questions[i].id;

              if (questions[i].type == 2 || questions[i].type == 4) {
                arr[key] = [];
              } else {
                arr[key] = "";
              }
            }

            this.selectedDta = arr;
            this.limitTime = content.exam_info.limit_time + "分";
            this.countdown(content.exam_info.limit_time);
          } else {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
              fun: function () {
                this.$router.go(-1);
              },
            });
          }
        });
    },
    downTimeOver(endTime){
      let timeValue = Date.now()
      let that = this
      this.downTime = setInterval(() => {
          if(timeValue>endTime){
            clearInterval(that.downTime)
            clearInterval(that.downTimer);
            that.subBtn()
          }
      },1000)
    },
    //倒计时
    countdown(date) {
      let _this = this;
      let maxtime = date * 60; //一个小时，按秒计算，自己调整!

      this.downTimer = setInterval(function () {
        if (maxtime >= 0) {
          let minutes = Math.floor(maxtime / 60);
          let seconds = Math.floor(maxtime % 60);
          let msg = minutes + "分" + (seconds != 0 ? seconds + "秒" : "");
          _this.limitTime = msg;
          // if (maxtime == 5 * 60)alert("还剩5分钟");
          --maxtime;
        } else {
          clearInterval(_this.downTimer);
          _this.subBtn();
        }
      }, 1000);
    },
    //提交
    subBtn() {
      let answers = [];
      let selectedDta = this.selectedDta;

      Object.keys(selectedDta).forEach(function (key) {
         if (key.split("_")[1] == 4) {
          answers.push({
          question_id: key.split("_")[2],
          option:
            selectedDta[key] instanceof Array
              ? selectedDta[key].join(",")
              : selectedDta[key],
        });
         }else{
          answers.push({
          question_id: key.split("_")[2],
          option:
            selectedDta[key] instanceof Array
              ? selectedDta[key].join("")
              : selectedDta[key],
        });
         }
        
      });
       let url =
        this.$route.params.type == "1"
          ? "/v1/personal/submitExam"
          : "/v1/openClass/submitExam";
      this.$axios
        .post(
          url,
          {
            exam_id: this.$route.params.id,
            record_id: this.content.record_id,
            answers,
          },
          { useLog: true }
        )
        .then((res) => {
          let _this = this;
          if (res.code == 0) {
            let text = '考试结束'
            if(res.data.is_show_score == 1){
              text += '，得分' + res.data.score + '分'
            }

            this.ShowTipModal({
              text,
              showBtns: true,
              cancelBtnText:'1',
              confirmFun: function () {
                _this.CloseTipModal();
                _this.$router.back();
              },
              cancelFun:function(){
                this.CloseTipModal();
                _this.$router.back();
              },
            });
          } else {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
              showBtns: true,
              confirmFun: function () {
                _this.CloseTipModal();
                _this.$router.back();
              },
              cancelFun:function(){
                this.CloseTipModal();
                _this.$router.back();
              },
            });
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.border{
  margin-left: 10px;
  border: 1px solid #999;
  padding: 10px;
  width: 300px !important;
}
.sheet {
  width: 44px;
  height: 44px;
  background: url(~@/assets/sheet-ico.png) no-repeat;
  background-size: auto 100%;
  position: fixed;
  top: 22px;
  right: 28px;
  z-index: 999;
}
.tip-bg {
  background: #fff;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 88px;
  z-index: 888;
  .tip {
    box-sizing: border-box;
    width: 100%;
    height: 62px;
    padding: 0 28px;
    background: rgba(245, 166, 35, 0.14);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .countdown {
      font-size: 26px;
      color: #999;
    }
    .date {
      font-size: 26px;
      color: #f5a623;
      margin-left: 15px;
      font-weight: bold;
    }
    .score {
      font-size: 26px;
      color: #666;
    }
  }
}
.title {
  padding: 28px;
  font-size: 32px;
  color: #333;
  font-weight: bold;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  .sub-title {
    font-size: 24px;
    color: #999;
    font-weight: normal;
  }
}
.category {
  padding: 0 28px;
}
.question-title {
  padding: 44px 0;
  font-size: 26px;
  color: #333;
}
.question-data {
  display: block;
  margin-bottom: 46px;
  font-size: 26px;
  color: #999;
  &.active {
    color: #254ed4;
  }
  input {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }
}
.sub-btn {
  display: block;
  width: 654px;
  height: 88px;
  background: #409eff;
  border-radius: 6px;
  margin: 60px auto;
  font-size: 28px;
  color: #fff;
}
.sheet-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  .content {
    box-sizing: border-box;
    width: 100%;
    padding: 47px 28px;
    background: #ffffff;
    position: absolute;
    left: 0;
    bottom: 0;
    .content-title {
      font-size: 32px;
      color: #333;
      position: relative;
    }
    .close {
      display: block;
      width: 50px;
      height: 50px;
      background: url(~@/assets/close-grey-ico.png) no-repeat center center;
      background-size: auto 23px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
.answer-sheet {
  padding: 28px 28px 44px;
  .category {
    padding: 0;
  }
  .category-title {
    font-size: 26px;
    color: #666;
    margin-bottom: 20px;
  }
  .test-num-box {
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border: 2px solid #cccccc;
    border-radius: 50%;
    float: left;
    margin-right: 48px;
    font-size: 26px;
    color: #999;
    &.active {
      background: rgba(37, 78, 212, 0.1);
      border: 2px solid rgba(37, 78, 212, 0.3);
      color: #254ed4;
    }
  }
}
</style>